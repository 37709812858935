.footer {
  // background-color: $color-yellow;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .site-footer {
    display: grid;
    gap: 0 0;
    grid-template-areas:
      "contacts-block"
      "brand-block"
      "footer-menu"
      "footer-legal";
    margin: 0 auto;
    max-width: $max-width;
    // padding: 10vw 0 0;

    @media #{$mq-900-up} {
      align-items: stretch;
      // align-items: start;
      // align-items: center;
      gap: 0;
      grid-template-columns: 35fr 40fr 25fr;
      grid-template-areas:
        "contacts-block brand-block footer-menu"
        "contacts-block footer-legal footer-legal";
      // justify-content: space-evenly;
      margin: 0 auto;
      padding: 0.5rem 1.5rem;
      padding: 0;
    }

    .contacts-block {
      @include font-size(18px);
      align-content: center;
      // align-self: stretch;
      background-color: $color-green;
      display: grid;
      grid-area: contacts-block;
      padding-inline: 10%;
      padding-block: 10% 15%;

      @media #{$mq-900-up} {
        @include font-size(18px);
        padding-inline: 20% 15%;
        padding-block: 0;

        .inner {
          align-self: center;
        }

      }

      h4 {
        @extend %small-caps;
        @include font-size(40px);
        font-weight: normal;
        margin-block: 0;
        text-transform: lowercase;
      }

      p {
        line-height: 1.333;
        margin-block: 0;
      }

      form {
        border-bottom: 2px solid $color-white;
        margin: 2rem 0 1rem;
        padding-block: 0;
        position: relative;
      }

      label {
        display: none;
        visibility: hidden;
      }

      .email {
        @extend %font-janson;
        @extend %small-caps;
        @include font-size(21px);
        background: transparent;
        border: none;
        color: $color-tan;
        // margin-left: -.5em;
        outline: none;
        padding: .5em;
        transition: all $trans-duration ease;
        width: 90%;

        &:focus {
          background: $color-tan;
          color: $color-black;
        }
      }

      .button {
        @extend %font-gotham-bold;
        @include font-size(21px);
        background: url('../img/button-gt.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 32px 18px;
        border: none;
        color: transparent;
        cursor: pointer;
        height: 48px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 12%;

        &:before {
          content: '>';
        }
      }

      .fineprint {
        @extend %font-neue-helvetica;
        @include font-size(11px);
        color: $color-tan;
        margin: 0;
        padding-bottom: 2rem;
        // width: 90%;
      }
    }

    .brand-block {
      @include font-size(20px);
      align-self: center;
      grid-area: brand-block;
      text-align: center;
      padding-block: 15% 0;
      padding-inline: 15%;

      @media #{$mq-900-up} {
        @include font-size(18px);
        padding-block: 10% 5%;
      }

      img {
        display: block;
        margin: 0 auto 1em;
        max-width: 400px;

        @media #{$mq-900-up} {
          margin: 0 auto;
          // width: 300px;
        }
      }

      p {
        @include font-size(20px);
        line-height: 1.5;
        margin-block: 0;
        margin-inline: auto;
        max-width: 33ch;
        padding-block: 0 1em;
      }

      .subhead {
        @extend %small-caps;
        @include font-size(24px);
        font-weight: normal;
        margin-block: 0;
        max-width: unset;
        padding-block: .5em 1em;
      }
    }

    .footer-menu {
      @extend %font-gotham-bold;
      @include font-size(21px);
      align-content: center;
      align-self: center;
      grid-area: footer-menu;
      padding: 0 7% 7%;

      @media #{$mq-760-up} {
        @include font-size(18px);
        padding: 10% 15%;
      }


      ul {
        list-style-type: none;
        margin-block: 0;
        margin-inline: 0;
        padding: 0;
        text-align: center;

        @media #{$mq-760-up} {
          text-align: left;
        }

        a,
        a:visited {
          display: inline-flex;
          padding-block: .6em;
          text-decoration: none;
          text-transform: uppercase;
          transition: color $trans-duration ease;
        }

        a:hover,
        a:active,
        a:focus {
          color: $color-white-actual;
          text-decoration: none;
        }
      }
    }

    .footer-legal {
      @extend %font-neue-helvetica;
      @include font-size(14px);
      @include letter-spacing(20);
      align-self: end;
      background-color: $color-green-extra-dark;
      display: flex;
      flex-direction: column;
      grid-area: footer-legal;
      line-height: 1.5;
      padding: 1.1em 10% .65em 10%;

      @media #{$mq-760-up} {
        flex-direction: row;
        padding: 1.1em 2em .65em 3.3em;
      }


      .copyright {
        @extend %font-neue-helvetica-italic;
        margin-block: 0;
        margin-block: 0;
        text-transform: uppercase;

        @media #{$mq-760-up} {
          margin-block: 0;
          margin-inline: 0 2em;
        }
      }

      ul {
        display: flex;
        column-gap: 2em;
        flex-direction: column;
        list-style-type: none;
        margin-block: 1em;
        padding: 0;

        @media #{$mq-760-up} {
          flex-direction: row;
          margin-block: 0;
        }

        a,
        a:visited {
          text-decoration: underline;
        }
      }
    }

    a,
    a:visited {
      text-decoration: none;
    }

    a:hover,
    a:active,
    a:focus {
      text-decoration: underline;
    }
  }

  .social-media ul {
    display: flex;
    // display: none;
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;

    @media #{$mq-900-up} {
      // display: flex;
    }

    a {
      background-size: 45px 45px;
      border-radius: 50%;
      color: transparent;
      display: block;
      font-size: 1px;
      height: 45px;
      margin: 0 .5rem;
      padding: 15px;
      transition: transform $trans-duration ease;
      width: 45px;

      @media #{$mq-900-up} {
        margin: 0 .75rem 0 0;
      }

    }

    .sm-fb a {
      background: transparent url('../img/sm-fb.svg') 50% 50% no-repeat;

      &:hover {
        transform: scale(1.1);
      }
    }

    .sm-ig a {
      background: transparent url('../img/sm-ig.svg') 50% 50% no-repeat;

      &:hover {
        transform: scale(1.1);
      }
    }

    .sm-tw a {
      background: transparent url('../img/sm-tw.svg') 50% 50% no-repeat;

      &:hover {
        transform: scale(1.1);
      }
    }

    .sm-yt a {
      background: transparent url('../img/sm-yt.svg') 50% 50% no-repeat;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}