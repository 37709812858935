.site-header {
  align-items: center;
  background: rgba($base-page-background, 0.9);
  box-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.3);
  backdrop-filter: blur(7px);
  height: 4.6875rem;
  left: 0;
  margin: 0 auto;
  // padding: 0 1rem;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5000;

  @media #{$mq-760-up} {
    align-items: unset;
    // display: block;
    height: auto;
  }

  @media #{$mq-900-up} {
    // background: $color-black;
    // background: none;
    // display: grid;
    // height: 6.25rem;
    // justify-content: center;
    padding: 1.5rem 0;
    position: relative;
  }

  @media #{$mq-760-down} {
    .mob-logo {
      display: block;

      img {
        display: block;
        height: auto;
        width: 155px;
      }
    }
  }

  .inner {
    // display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: $max-width;
    position: relative;
    width: 100%;

    @media #{$mq-900-up} {
      // align-items: center;
      // display: grid;
      // gap: 2rem;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      left: auto;
      padding: 0 3.75%;
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
    }
  }

  .primary {
    @extend %font-gotham-bold;
    // @include font-size(27px);
    @include letter-spacing(10);
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow-y: scroll;
    // padding: 15vh 8vw 0;
    position: fixed;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transform: translateY(-5%);
    transition: opacity $trans-duration ease-in-out;
    visibility: hidden;
    z-index: 4000;

    @media #{$mq-900-up} {
      // align-content: center;
      // display: flex;
      display: grid;
      // font-size: 2.7vw;
      grid-template-columns: 2fr 1fr 2fr;
      // justify-content: space-between;
      // justify-content: start;
      opacity: 1;
      overflow-y: unset;
      padding: 0;
      position: relative;
      transform: translateY(0);
      visibility: visible;
      width: 100%;
    }

    .header-logo {
      display: block;
      justify-self: center;

      img {
        display: block;
        height: 50px;
        // height: auto;
        max-width: 208px;

        @media #{$mq-900-up} {
          height: auto;
          width: 12vw;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      max-width: $max-width;
      // opacity: 0;
      padding: 0;
      position: relative;

      @media #{$mq-900-up} {
        align-self: center;
        display: flex;
        justify-self: flex-start;

        &:nth-of-type(1) {
          justify-self: flex-start;
        }

        &:nth-of-type(2) {
          justify-self: flex-end;
        }
      }

      a {
        padding: 0.5em 0;
        margin: 0 0.5em;
        text-decoration: none;
        white-space: nowrap;

        @media #{$mq-760-up} {
          // @include font-size(11px);
          margin: 0 .5em;
        }

        @media #{$mq-900-up} {
          @include font-size(12px);
          // padding: 0.5em 0;
          margin: 0 1.25em;
        }

        @media #{$mq-1180-up} {
          @include font-size(16px);
          margin: 0 1.25em;
        }

        &:hover,
        &:active,
        &:focus {}
      }
    }

    ul li {
      display: block;
      position: relative;

      @media #{$mq-900-up} {
        // display: inline-block;
        // grid-template-columns: 1fr 2fr;
        // gap: 5%;
      }
    }
  }

  //     .menu-item-has-children {
  //       ul {
  //         // background: rgba($color-black, 0.9);
  //         // @extend %font-gotham-medium;
  //         @include font-size(16px);
  //         border-radius: 0 0 0.25em 0.25em;
  //         // display: none;
  //         min-width: 100%;
  //         // padding: 0.5em 0 1.5em;
  //         padding: 0 0 1.5em;
  //         // top: 100px;
  //         transition: opacity $trans-duration linear;

  //         // @media #{$mq-900-up} {
  //         // 	background: linear-gradient(
  //         // 		310deg,
  //         // 		#7727e8,
  //         // 		#4ef2f2 100%
  //         // 	);
  //         // 	left: 0.5em;
  //         // 	opacity: 0;
  //         // 	padding: 1em 0 1em;
  //         // 	position: absolute;
  //         // 	visibility: hidden;
  //         // }

  //         li {
  //           display: block;
  //         }


  //       }

  //       &:hover,
  //       &:active,
  //       &:focus {
  //         @media #{$mq-900-up} {
  //           ul {
  //             display: block;
  //             opacity: 1;
  //             visibility: visible;
  //           }
  //         }
  //       }
  //     }

  //     a {
  //       border-left: 0.125rem transparent solid;
  //       color: $color-white;
  //       display: block;
  //       line-height: 1;
  //       // padding: 0 0 1.125em;
  //       // padding: 0 0 0.25em;
  //       padding: 0.25em 0 0.5em 1.25em;
  //       position: relative;
  //       text-decoration: none;
  //       transition: all $trans-duration linear;

  //       @media #{$mq-900-up} {
  //         // margin: 0 2em;
  //         // padding: 0.25em 0;
  //       }

  //       @media #{$mq-1180-up} {
  //         // padding: 0.25em 0;
  //       }
  //     }

  //     .current-menu-item>a,
  //     .current-page-parent>a,
  //     a:hover,
  //     a:focus,
  //     a:active {
  //       color: $color-yellow;
  //       border-left: 0.125rem $color-yellow solid;
  //       // color: $color-blue-med;

  //       // @media #{$mq-900-up} {
  //       // 	&:after {
  //       // 		opacity: 1;
  //       // 		transform: scaleX(1);
  //       // 		visibility: visible;
  //       // 	}
  //       // }
  //     }
  //   }

  &.active {
    background: rgba($base-page-background, 0.8);
    // backdrop-filter: blur(5px);
    height: 100%;
    opacity: 1;

    .mob-logo {
      display: none;
      visibility: hidden;
    }

    .inner {
      background-image: url('../img/bkg-sm-menu.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 5000;
    }

    .primary {
      align-content: start;
      justify-items: center;
      display: grid;
      grid-template-columns: 1fr;
      opacity: 1;
      overflow: revert;
      transform: translateY(0);
      visibility: visible;

      @media #{$mq-900-down} {
        @include font-size(25px);
        padding-top: 25%;
        text-align: center;

        .header-logo {
          grid-row: 1;
          padding: 0 0 2rem;
        }

        ul:nth-child(1) {
          grid-row: 2;
        }

        ul:nth-child(2) {
          grid-row: 3;
        }

        ul li a {
          display: block;
          padding: .5em 0;
        }
      }

      @media #{$mq-900-up} {
        display: block;
      }
    }
  }

  @keyframes burger_top_out {
    0% {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-45deg) translate(-5px, 7px);
    }
  }

  @keyframes burger_bottom_out {
    0% {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(45deg) translate(-5px, -5px);
      width: 100%;
    }
  }

  @keyframes burger_middle_out {
    0% {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  .page-header-menu-button {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: $color-white;
    cursor: pointer;
    display: block;
    height: 60px;
    // left: 0.5rem;
    margin: 0;
    outline: 0;
    overflow: hidden;
    position: absolute;
    right: 11px;
    top: 10px;
    visibility: visible;
    width: 60px;
    z-index: 6000;

    @media #{$mq-900-up} {
      display: none;
      visibility: hidden;
    }

    .burger {
      display: block;
      margin: 0 auto;
      max-width: 31px;

      .burger-bottom,
      .burger-middle,
      .burger-top {
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        background-color: $color-white;
        display: block;
        height: 2px;
        margin-bottom: 6px;
        transform-origin: center center;
      }
    }

    @media #{$mq-900-up} {
      // display: none;
      // right: 3rem;
      right: 1.75%;
      top: 1rem;
    }
  }

  .page-header-menu-button.active {
    .burger {
      .burger-top {
        animation-name: burger_top_out;
      }

      .burger-middle {
        animation-duration: 0.3s;
        animation-name: burger_middle_out;
      }

      .burger-bottom {
        animation-name: burger_bottom_out;
      }
    }
  }
}