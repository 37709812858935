// @import url('https://cloud.typography.com/7031652/6405632/css/fonts.css');

// TODO: move HTF Knockout to production mode at deployment. (host font files locally)
/* @import must be at top of file, otherwise Knockout will not work */
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gotham Bold by Hoefler &amp; Co.
 * URL: https://www.myfonts.com/collections/gotham-font-hoefler-and-co
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Neue Helvetica 66 Medium Italic by Linotype
 * URL: https://www.myfonts.com/products/pro-66-medium-italic-189171-neue-helvetica-369228
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Neue Helvetica 65 Medium by Linotype
 * URL: https://www.myfonts.com/products/pro-65-medium-189171-neue-helvetica-369223
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Janson Text 75 Bold by Linotype
 * URL: https://www.myfonts.com/products/75-bold-janson-text-371469
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Janson Text 76 Bold Italic by Linotype
 * URL: https://www.myfonts.com/products/76-bold-italic-janson-text-371473
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Janson Text 56 Italic by Linotype
 * URL: https://www.myfonts.com/products/56-italic-janson-text-371478
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Janson Text 55 Roman by Linotype
 * URL: https://www.myfonts.com/products/55-roman-janson-text-371482
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */

@font-face {
  font-family: "Knockout-49";
  src: url('../fonts/Knockout49Liteweight/font.woff2') format('woff2'),
    url('../fonts/Knockout49Liteweight/font.woff') format('woff');
}

@font-face {
  font-family: "Gotham-Bold";
  src: url('../fonts/GothamBold/font.woff2') format('woff2'),
    url('../fonts/GothamBold/font.woff') format('woff');
}

@font-face {
  font-family: "Janson";
  src: url('../fonts/JansonText55Roman/font.woff2') format('woff2'),
    url('../fonts/JansonText55Roman/font.woff') format('woff');
}

@font-face {
  font-family: "Janson-Italic";
  src: url('../fonts/JansonText56Italic/font.woff2') format('woff2'),
    url('../fonts/JansonText56Italic/font.woff') format('woff');
}

@font-face {
  font-family: "Janson-Bold";
  src: url('../fonts/JansonText75Bold/font.woff2') format('woff2'),
    url('../fonts/JansonText75Bold/font.woff') format('woff');
}

@font-face {
  font-family: "Janson-Bold-Italic";
  src: url('../fonts/JansonText76BoldItalic/font.woff2') format('woff2'),
    url('../fonts/JansonText76BoldItalic/font.woff') format('woff');
}

@font-face {
  font-family: "Neue-Helvetica-Medium";
  src: url('../fonts/NeueHelvetica65Medium/font.woff2') format('woff2'),
    url('../fonts/NeueHelvetica65Medium/font.woff') format('woff');
}

@font-face {
  font-family: "Neue-Helvetica-Medium-Italic";
  src: url('../fonts/NeueHelvetica66MediumItalic/font.woff2') format('woff2'),
    url('../fonts/NeueHelvetica66MediumItalic/font.woff') format('woff');
}

%font-knockout {
  font-family: "Knockout-49";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

%font-gotham-bold {
  font-family: "Gotham-Bold";
  font-style: normal;
}

%font-janson {
  font-family: "Janson";
  font-style: normal;
  font-weight: normal;
}

%font-janson-italic {
  font-family: "Janson-Italic";
  font-style: normal;
  font-weight: normal;
}

%font-janson-bold {
  font-family: "Janson-Bold";
  font-style: normal;
  font-weight: normal;
}

%font-janson-janson-italic {
  font-family: "Janson-Bold-Italic";
  font-style: normal;
  font-weight: normal;
}

%font-neue-helvetica {
  font-family: "Neue-Helvetica-Medium";
  font-style: normal;
  font-weight: normal;
}

%font-neue-helvetica-italic {
  font-family: "Neue-Helvetica-Medium-Italic";
  font-style: normal;
  font-weight: normal;
}