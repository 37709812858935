// SASS version of global variables

$rfs-breakpoint: 1920px;

$color-white-actual: #fff;
$color-white: #E2D9C4;
$color-black: #1A1A1A;
$color-black-actual: #000;
$color-mid-gray: #707070;
$color-tan: #D7D1C4;
$color-green-light: #44574A;
$color-green: #3D4E42;
$color-green-dark: #2F3D34;
$color-green-extra-dark: #2B362E;
$color-rust: #B0351F;
$color-rust-light: #A6291A;

$color-socmed-fb: #43609c;
$color-socmed-ig: #b9378c;
$color-socmed-li: #1178b3;
$color-socmed-tw: #55acee;
$color-socmed-yt: #f90d1b;
$base-page-background: $color-green-light;
$base-font-color: $color-white;
$base-link-color: $color-white;
$trans-duration: 200ms;
$max-width: 1920px;

// Media Queries

$mq-620-down: "only screen and (max-width: 620px)";
$mq-620-up: "only screen and (min-width: 620px)";

$mq-760-down: "only screen and (max-width: 759px)";
$mq-760-up: "only screen and (min-width: 760px)";

$mq-900-down: "only screen and (max-width: 899px)";
$mq-900-up: "only screen and (min-width: 900px)";

$mq-1000-down: "only screen and (max-width: 999px)";
$mq-1000-up: "only screen and (min-width: 1000px)";

$mq-1180-up: "only screen and (min-width: 1180px)";

$mq-1400-up: "only screen and (min-width: 1400px)";

$mq-1600-up: 'only screen and (min-width: 1600px)';