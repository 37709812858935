%bold-headline {
  @extend %font-gotham-bold;
  // @include font-size(30px);
  // @include letter-spacing(50);
  line-height: 1.1;
  margin: 0;
  padding: 0 0 1em;
  text-align: center;
  text-transform: uppercase;

  @media #{$mq-900-up} {
    font-size: 2.7vw;
  }

  @media #{$mq-1400-up} {
    @include font-size(45px);
  }
}

%condensed-headline {
  @extend %font-knockout;
}

%base-graf {
  @extend %font-janson;
  @include font-size(18px);
  @include letter-spacing(10);
  line-height: 1.2;
  margin: 0;
  padding: 0 0 1em;

  @media #{$mq-900-up} {
    font-size: 1.8vw;
  }

  @media #{$mq-1400-up} {
    @include font-size(27px);
  }
}

%small-caps {
  font-variant-caps: small-caps;
  font-variant-numeric: oldstyle-nums stacked-fractions;
}

.copy ul {
  @extend %base-graf;
  line-height: 1.35;
  padding: 0 0 0 1em;

  li {
    padding-bottom: 0.75em;
  }
}

.article-body p {
  // @extend %base-graf;
}