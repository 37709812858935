.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

html {
  box-sizing: border-box;
  font-size: 100%;

  @media #{$mq-900-up} {
    min-height: 100%;
    position: relative;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// input[type='button'],
// input[type='submit'],
// input[type='reset'] {
//   -webkit-appearance: none;
// }


::selection {
  background: rgba($color-green-light, 0.4);
}

body {
  //   font-family: 'Gotham Book', Gotham-Book, Helvetica, Arial, sans-serif;
  // @extend %font-janson;
  @extend %font-janson;
  background: $base-page-background;
  // background-image: url("../img/bkg-paper.jpg");
  background-repeat: repeat;
  background-size: contain;
  color: $base-font-color;
  line-height: 1.3;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  position: relative;
  //   background-attachment: fixed;

  @media #{$mq-900-up} {
    margin: 0;
  }
}

a {
  color: $base-link-color;
}

.outer-content {
  background-color: $color-black;
  margin: 0 auto;
  padding: 4.25rem 0 0;

  @media #{$mq-900-up} {
    padding: 0;
  }

  // .content-wrapper {
  // 	margin: 0 auto;
  // 	max-width: $max-width;
  // 	padding: 0;
  // }
}

.content-wrapper {
  margin: 4.6875rem auto 0;
  max-width: $max-width;
  padding: 0;

  @media #{$mq-760-up} {
    margin: 0 auto;
  }

}

img {
  display: block;
  height: auto;
  max-width: 100%;
  position: relative;
}

video {
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.article-h1 {
  margin: 0;
}

%btn {
  @extend %font-neue-helvetica;
  @include font-size(14px);
  @include letter-spacing(20);
  background-color: $color-green-light;
  border: 0;
  border-radius: 2em;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 1.25em 5em 1.125em;
  vertical-align: baseline;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $trans-duration ease, color $trans-duration ease;

  // @media #{$mq-900-up} {
  //   font-size: 2vw;
  // }

  // @media #{$mq-1400-up} {
  //   @include font-size(14px);
  // }

  &:hover,
  &:focus,
  &:active {
    background-color: $color-green;
    color: $color-tan;
  }
}

.pagination-block {
  align-items: center;
  display: flex;
  padding-block: 2rem;

  .pagination {
    display: flex;
    column-gap: 0.25rem;
    list-style: none;
    margin: 0 auto;
    padding: 0;

    @media #{$mq-760-up} {
      gap: 0.25rem;
      // grid-template-columns: repeat(4, 1fr);
    }
  }

  li,
  .prev,
  .next,
  button {
    @extend %font-neue-helvetica;
    @include font-size(14px);
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    margin: 0;
    line-height: 1;
    opacity: 1;
    padding: 0;
    text-transform: uppercase;
    transition: background-color $trans-duration ease,
      color $trans-duration ease;

    a,
    a:visited {
      border: 2px solid $color-green;
      color: $color-green;
      line-height: 1;
      padding: .6em .75em .4em;
      text-decoration: none;
      transition: background-color $trans-duration linear;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-green;
        color: $color-white;
      }
    }
  }

  .current {
    span {
      border: 2px solid $color-green;
      background-color: $color-green-dark;
      color: $color-white;
      padding: .6em .75em .4em;
    }

    .dots {
      background-color: transparent;
      color: lighten($color-black, 70%);
    }
  }

  .disabled {
    opacity: 0;
    user-select: none;
  }

  @media #{$mq-900-down} {
    li {
      display: none;
    }
  }
}