.home {
  .page-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding: 0 5% 0 7.5%;
    width: auto;

    @media #{$mq-760-up} {
      align-items: center;
      background-size: cover;
      // column-gap: 4%;
      column-gap: 0;
      display: grid;
      grid-template-columns: 3.4fr 6.6fr;
      flex-direction: row;
      padding: 0;
    }

    .hero-img {
      align-self: center;
      margin-top: 5%;
      margin-right: 15%;
      width: 66%;

      @media #{$mq-760-up} {
        margin-left: 0;
        margin-right: unset;
        margin-top: 0;
        justify-self: end;
        width: auto;
      }
    }

    .page-title {
      @extend %condensed-headline;
      @include font-size(150px);
      line-height: 1;
      margin-block: 0.75em 0;
      max-width: 18ch;
      text-transform: uppercase;

      @media #{$mq-760-up} {
        @include font-size(90px);
        margin-block: 0;
      }
    }

    h2 {
      @include font-size(24px);
      font-weight: normal;
      line-height: 1;
      margin-block: 0.5em;

      @media #{$mq-760-up} {
        @include font-size(32px);
      }
    }

    .btns {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-block: 2rem 1rem;

      @media #{$mq-760-up} {
        flex-direction: row;
      }

      .btn {
        @extend %btn;
        box-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.3);
        text-align: center;
      }

      .alt {
        @extend %btn;
        background-color: $color-rust-light;
        padding-inline: 3.5em;

        &:focus,
        &:hover {
          background-color: $color-rust;
        }
      }
    }
  }

  .featured-drinks-block {
    background-color: $color-white;
    background-image: url("../img/bkg-paper-darker.jpg");
    background-repeat: repeat;
    color: $color-black;
    padding-block: 15% 5%;
    padding-inline: 5%;

    @media #{$mq-760-up} {
      padding-block: 5% 5%;
      padding-inline: 15%;
    }

    a,
    a:visited {
      color: $color-black;
      text-decoration: none;
    }

    a:hover,
    a:active,
    a:focus {
      text-decoration: underline;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      align-items: start;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 2rem;

      @media #{$mq-760-up} {
        column-gap: 2.5rem;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: unset;
        // padding-block: 3rem;
      }
    }

    h3 {
      @extend %small-caps;
      @include font-size(35px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0.5em 0.25em;
    }

    .subhead {
      @extend %font-janson-italic;
      @include font-size(18px);
      line-height: 1;

      a {
        text-decoration: underline;
      }
    }

    .btns {
      padding-block: 1rem 2rem;

      @media #{$mq-760-up} {
        padding-block: 2rem 0;
        text-align: center;
      }

      .btn {
        @extend %btn;
      }
    }
  }

  .flagship {
    align-items: center;
    background: $color-black-actual;
    color: $color-white;
    display: grid;
    grid-template-columns: 1fr;
    padding-block: 5% 0;
    padding-inline: 0;

    @media #{$mq-760-up} {
      grid-template-columns: 1fr 1fr;
      padding-block: 4% 0;
      padding-inline: 5%;
    }

    @media #{$mq-900-up} {
      padding-inline: 10%;
    }

    @media #{$mq-1180-up} {
      padding-inline: 20%;
    }

    .photo {
      grid-row: 2;

      @media #{$mq-760-up} {
        grid-row: 1;
      }

      img {
        width: 100%;
      }
    }

    .block-text {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // padding-inline: 0;
      padding-block: 5% 10%;
      padding-inline: 10%;

      @media #{$mq-760-up} {
        padding-block: 0;
        // padding-inline: 0 0;
      }

      @media #{$mq-900-up} {
        // padding-inline: 1% 0;
      }

      @media #{$mq-1180-up} {
        padding-inline: 5% 0;
      }

      h2 {
        @extend %font-gotham-bold;
        @include font-size(35px);
        font-weight: normal;
        margin-block: 0 0;
        text-transform: uppercase;

        @media #{$mq-900-up} {
          // @include font-size(60px);
          font-size: clamp(35px, 4vw, 60px);
        }
      }

      .small-caps {
        @extend %font-janson;
        @extend %small-caps;
        @include font-size(21px);
        color: $color-rust;
        display: block;
        line-height: 1;
        text-transform: lowercase;

        // @media #{$mq-760-up} {
        //   @include font-size(35px);
        // }

        @media #{$mq-900-up} {
          // @include font-size(35px);
          font-size: clamp(20px, 2.5vw, 35px);
        }
      }

      .block-copy {
        @include font-size(18px);
        line-height: 1.5;

        @media #{$mq-900-up} {
          // @include font-size(25px);
          font-size: clamp(16px, 1.5vw, 25px);
        }
      }

      .attributes {
        @extend %small-caps;
        @include font-size(20px);
        display: block;
        line-height: 1;
        margin-block: 1.5em 0.75em;
        text-transform: lowercase;

        // @media #{$mq-760-up} {
        @media #{$mq-760-up} {
          // @include font-size(25px);
          font-size: clamp(16px, 1.5vw, 25px);
        }
      }

      .note {
        @extend %font-janson-italic;
        @include font-size(14px);
        display: block;
        line-height: 1.4;
        margin-block: 0 0.75em;

        @media #{$mq-760-up} {
          @include font-size(16px);
        }
      }

      .btns {
        padding-block: 1rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // padding-block: 2rem 1rem;

        @media #{$mq-760-up} {
          flex-direction: row;
          padding-block: 1rem 0;
          // text-align: center;
        }

        .btn {
          @extend %btn;
          background-color: $color-rust-light;
          padding-inline: 3.5em;
          text-align: center;

          &:focus,
          &:hover {
            background-color: $color-rust;
          }
        }
        .alt {
          @extend %btn;
          background-color: $color-green;
          padding-inline: 3.5em;

          &:focus,
          &:hover {
            background-color: $color-green-light;
          }
        }
      }
    }
  }

  .products {
    background-color: $color-white;
    background-image: url("../img/bkg-paper-darker.jpg");
    background-repeat: repeat;
    color: $color-black;
    display: grid;
    grid-template-columns: 1fr;
    padding-block: 15% 5%;
    padding-inline: 5%;
    row-gap: 1rem;

    @media #{$mq-760-up} {
      column-gap: 15%;
      grid-template-columns: 1fr 1fr;
      padding-block: 5% 5%;
      padding-inline: 10%;
      row-gap: unset;
    }

    .product {
      align-items: start;
      display: grid;
      grid-template-columns: 2fr 6fr;

      @media #{$mq-760-up} {
        align-items: center;
        grid-template-columns: 4.5fr 5.5fr;
      }

      img {
        width: 100%;
      }
    }

    a,
    a:visited {
      color: $color-black;
      text-decoration: none;
    }

    a:hover,
    a:active,
    a:focus {
      text-decoration: underline;
    }

    .block-text {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // padding-inline: 0;
      padding-block: 0;
      padding-inline: 10%;

      @media #{$mq-760-up} {
        padding-block: 0;
        padding-inline: 5% 0;
      }

      h2 {
        @extend %font-gotham-bold;
        @include font-size(35px);
        font-weight: normal;
        margin-block: 0 0;
        text-transform: uppercase;

        @media #{$mq-760-up} {
          @include font-size(60px);
        }
      }

      .small-caps {
        @extend %font-janson;
        @extend %small-caps;
        @include font-size(21px);
        color: $color-black;
        display: block;
        line-height: 1;
        text-transform: lowercase;

        @media #{$mq-760-up} {
          @include font-size(35px);
        }
      }

      .block-copy {
        @include font-size(18px);
        line-height: 1.5;

        @media #{$mq-760-up} {
          @include font-size(25px);
        }
      }

      .attributes {
        @extend %small-caps;
        @include font-size(20px);
        display: block;
        line-height: 1;
        margin-block: 1.5em 0.75em;
        text-transform: lowercase;

        @media #{$mq-760-up} {
          @include font-size(25px);
        }
      }

      .note {
        @extend %font-janson-italic;
        @include font-size(14px);
        display: block;
        line-height: 1.4;
        margin-block: 0 0.75em;

        @media #{$mq-760-up} {
          @include font-size(16px);
        }
      }

      .btns {
        padding-block: 1rem 2rem;

        @media #{$mq-760-up} {
          padding-block: 1rem 0;
          // text-align: center;
        }

        .btn {
          @extend %btn;
          background-color: $color-black;
          padding-inline: 1.5em;

          @media #{$mq-760-up} {
            padding-inline: 3.5em;
          }

          &:focus,
          &:hover {
            background-color: $color-black-actual;
          }
        }
      }
    }
  }
}

.page-template-page-merch {
  .article-body {
    background-color: $color-white;
    background-image: url("../img/bkg-paper.jpg");
    background-repeat: repeat;
    color: $color-black;
    padding-block: 15% 5%;
    padding-inline: 5%;

    @media #{$mq-760-up} {
      padding-block: 5% 5%;
      padding-inline: 15%;
    }

    h1 {
      @extend %small-caps;
      @include font-size(35px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0;
      text-align: center;

      @media #{$mq-760-up} {
        @include font-size(50px);
      }
    }

    p {
      @include font-size(18px);
      font-weight: normal;
      line-height: 1.4;
      margin-inline: auto;
      max-width: 28ch;
      text-align: center;

      @media #{$mq-760-up} {
        @include font-size(21px);
        max-width: 34ch;
      }
    }

    a,
    a:visited {
      color: $color-green-dark;
      text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus {
      color: $color-green;
      text-decoration-color: $color-rust-light;
    }

    .featured-merch-block {
      padding-block: 15% 5%;

      @media #{$mq-760-up} {
        padding-block: 5% 5%;
      }

      a,
      a:visited {
        color: $color-black;
        text-decoration: none;
      }

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }

      p {
        text-align: left;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        align-items: start;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2rem;

        @media #{$mq-900-up} {
          column-gap: 2.5rem;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: unset;
          // padding-block: 3rem;
        }
      }

      h3 {
        // @extend %small-caps;
        // @include font-size(21px);
        // line-height: 1.1;
        @extend %font-gotham-bold;
        @include font-size(22px);
        font-weight: normal;
        line-height: 1.2;
        margin-block: 0.5em 0.25em;
        text-transform: uppercase;

        @media #{$mq-900-up} {
          margin-block: 1em 1em;
        }
      }

      .subhead {
        @extend %font-janson-italic;
        @include font-size(18px);
        line-height: 1;
        margin-inline: 0;

        a {
          text-decoration: underline;
        }
      }

      .btns {
        padding-block: 1rem 2rem;

        @media #{$mq-760-up} {
          padding-block: 2rem 0;
          text-align: center;
        }

        .btn {
          @extend %btn;
        }
      }
    }
  }
}

.page-template-page-about {
  .page-hero {
    align-items: center;
    background: $color-black;
    display: grid;
    grid-template-columns: 1fr;

    @media #{$mq-760-up} {
      grid-template-columns: 6.4fr 3.6fr;
    }

    .hero-text {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      line-height: 1.1;
      // margin-block: 0.25em;
      padding-block: 20%;
      padding-inline: 10%;
      text-align: center;

      @media #{$mq-760-up} {
        padding-block: 0;
        padding-inline: 20%;
        text-align: left;
      }

      h2 {
        @include font-size(27px);
        font-weight: normal;
        line-height: 1.375;
        margin-block: 0;
        margin-inline: auto;

        @media #{$mq-760-up} {
          @include font-size(40px);
        }
      }
    }

    .hero-img {
      grid-column: 1;
      grid-row: 2;

      .sm {
        display: block;
        visibility: visible;
      }

      .lg {
        display: none;
        visibility: hidden;
      }

      img {
        width: 100%;
      }
    }

    .block-text {
      grid-column: 1;
      grid-row: 1;
    }

    @media #{$mq-760-up} {
      .hero-img {
        grid-row: 1;

        .sm {
          display: none;
          visibility: hidden;
        }

        .lg {
          display: block;
          visibility: visible;
        }
      }

      .block-text {
        grid-column: 2;

        grid-row: 1;
      }
    }
  }

  .story-block-2col-full {
    .block-text {
      .block-copy {
        @include font-size(27px);
        font-weight: normal;
        line-height: 1.375;
        margin-inline: auto;

        @media #{$mq-760-up} {
          @include font-size(40px);
          text-align: left;
        }
      }
    }
  }
}

.story-block-2col-full {
  align-items: center;
  background: $color-black;
  display: grid;
  grid-template-columns: 1fr;
  overflow-x: hidden;

  @media #{$mq-760-up} {
    grid-template-columns: 6.4fr 3.6fr;
  }

  .block-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    line-height: 1.1;
    // margin-block: 0.25em;
    padding-block: 20%;
    padding-inline: 10%;
    text-align: center;

    @media #{$mq-760-up} {
      padding-block: 10%;
      padding-inline: 2%;
    }

    @media #{$mq-900-up} {
      padding-block: 0;
      padding-inline: 5%;
    }

    @media #{$mq-1180-up} {
      padding-inline: 10%;
    }

    @media #{$mq-1400-up} {
      padding-inline: 20%;
    }

    h2 {
      @extend %small-caps;
      @include font-size(25px);
      margin-block: 0;
      margin-inline: auto;
      max-width: 20ch;

      @media #{$mq-760-up} {
        @include font-size(20px);
      }

      @media #{$mq-760-up} {
        @include font-size(22px);
      }

      @media #{$mq-900-up} {
        @include font-size(24px);
      }

      @media #{$mq-1180-up} {
        @include font-size(27px);
      }
    }

    .rule {
      background-image: url("../img/rule-arrows.svg");
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 11px;
      margin-inline: auto;
      margin-block: 1.5rem;
      width: 90px;
    }

    .block-copy {
      @include font-size(25px);

      @media #{$mq-760-up} {
        @include font-size(15px);
      }

      @media #{$mq-900-up} {
        @include font-size(16px);
      }

      @media #{$mq-1180-up} {
        @include font-size(18px);
      }

      font-weight: normal;
      line-height: 1.5;
    }
  }

  .photo {
    grid-column: 1;
    grid-row: 2;

    .sm {
      display: block;
      visibility: visible;
    }

    .lg {
      display: none;
      visibility: hidden;
    }

    img {
      width: 100%;
    }
  }

  .block-text {
    grid-column: 1;
    grid-row: 1;
  }

  @media #{$mq-760-up} {
    .photo {
      grid-row: 1;

      .sm {
        display: none;
        visibility: hidden;
      }

      .lg {
        display: block;
        visibility: visible;
      }
    }

    .block-text {
      grid-column: 2;

      grid-row: 1;
    }
  }

  &.text-left {
    @media #{$mq-760-up} {
      align-items: center;
      grid-template-columns: 3.6fr 6.4fr;

      .photo {
        grid-column: 2;
        grid-row: 1;
      }

      .block-text {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }
}

.story-block-photo-headline {
  position: relative;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);

  clip-path: inset(0 0 0 0);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;

  .photo {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    grid-column: 1;
    grid-row: 2;

    .sm {
      // height: 100% !important;
      height: 100%;
      object-fit: cover;
      display: block;
      visibility: visible;
    }

    .lg {
      display: none;
      visibility: hidden;
    }

    img {
      width: 100%;
    }
  }

  @media #{$mq-760-up} {
    .photo {
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 0;
      transform: translate3d(0, 0, 0);

      grid-row: 1;

      .sm {
        display: none;
        visibility: hidden;
      }

      .lg {
        // height: 100% !important;
        height: 100%;
        object-fit: cover;
        display: block;
        visibility: visible;
      }
    }
  }

  .block-text {
    margin-inline: auto;
    margin-block: 0;
    padding-inline: 10%;
    position: absolute;

    position: fixed;
    overflow: hidden;

    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .overlay {
      @extend %font-knockout;
      // @include font-size(73px);
      @include font-size(200px);
      display: block;
      font-weight: normal;
      line-height: 1;
      margin-block: 0;
      // text-shadow: 0 0 0.2em $color-black;
      text-transform: uppercase;

      @media #{$mq-760-up} {
        @include font-size(108px);
      }
    }
  }
}

.story-block-photo-paragraph {
  position: relative;

  .photo {
    grid-column: 1;
    grid-row: 2;

    .sm {
      display: block;
      visibility: visible;
    }

    .lg {
      display: none;
      visibility: hidden;
    }

    img {
      width: 100%;
    }
  }

  @media #{$mq-760-up} {
    .photo {
      grid-row: 1;

      .sm {
        display: none;
        visibility: hidden;
      }

      .lg {
        display: block;
        visibility: visible;
      }
    }
  }

  .block-text {
    margin-inline: auto;
    padding-inline: 15%;
    position: absolute;
    text-align: center;
    top: 60%;
    transform: translateY(-50%);
    width: 100%;

    .overlay {
      @include font-size(27px);
      display: block;
      font-weight: normal;
      line-height: 1.25;
      margin-inline: auto;
      text-shadow: 0 0 0.3em $color-black, 0 0 0.6em $color-black;

      @media #{$mq-760-up} {
        @include font-size(67px);
        max-width: 22ch;
      }
    }
  }
}

.story-block-2col-inset {
  align-items: center;
  background: $color-white;
  background-image: url("../img/bkg-paper-darker.jpg");
  background-repeat: repeat;
  color: $color-black;
  display: grid;
  grid-template-columns: 1fr;
  padding-block: 0;
  padding-inline: 0;

  @media #{$mq-760-up} {
    grid-template-columns: 1fr 1fr;
    padding-inline: 20%;
  }

  .photo {
    grid-row: 2;

    @media #{$mq-760-up} {
      grid-row: 1;
    }

    img {
      width: 100%;
    }
  }

  .block-text {
    // padding-inline: 0;
    padding-block: 5% 10%;
    padding-inline: 10%;

    @media #{$mq-760-up} {
      padding-block: 15% 20%;
      padding-inline: 20% 0;
    }

    h2 {
      @include font-size(27px);
      font-weight: normal;

      @media #{$mq-760-up} {
        @include font-size(40px);
      }
    }

    .small-caps {
      @extend %small-caps;
      @include font-size(21px);
      display: block;
      text-transform: lowercase;

      @media #{$mq-760-up} {
        @include font-size(30px);
      }
    }

    .block-copy {
      @include font-size(18px);
      line-height: 1.5;

      @media #{$mq-760-up} {
        @include font-size(25px);
      }
    }
  }
}

.archive .content-wrapper > h1 {
  display: none;
}

.blog,
.archive {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper-darker.jpg");
    background-repeat: repeat;
    color: $color-black;
    padding-block: 0 5%;
    padding-inline: 5%;

    @media #{$mq-760-up} {
      padding-block: 0 5%;
      padding-inline: 7%;
    }

    .article-excerpts {
      .inner-wrap {
        display: grid;
        grid-template-columns: 1fr;
        padding-block: 10% 0;

        @media #{$mq-760-up} {
          column-gap: 5%;
          row-gap: 3rem;
          grid-template-columns: 1fr 1fr 1fr;
          padding-block: 5%;
        }

        .h2 {
          @extend %font-gotham-bold;
          @include font-size(25px);
          font-weight: normal;
          line-height: 1.4;
          margin-block: 0.25em 0;

          @media #{$mq-760-up} {
            margin-block: 0.25em 2em;
          }
        }

        .byline {
          @extend %font-neue-helvetica;
          @include font-size(14px);
          margin: 0;
          padding-block: 2.5em 0;
          text-transform: uppercase;
        }

        p {
          @include font-size(18px);
          font-weight: normal;
          line-height: 1.25;
          max-width: 50ch;

          @media #{$mq-760-up} {
            @include font-size(21px);
          }
        }

        .read-more {
          @extend %font-neue-helvetica;
          @include font-size(16px);
          display: block;
          margin: 0;
          margin-block: 2.5em;
          // padding-block: 2.5em 0;
          text-decoration: underline;
        }

        a,
        a:visited {
          color: $color-black;
          text-decoration: none;
        }

        a:hover,
        a:active,
        a:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .featured-post {
    align-items: center;
    background: $color-black;
    display: grid;
    grid-template-columns: 1fr;

    @media #{$mq-760-up} {
      grid-template-columns: 1fr 1fr;
    }

    .block-text {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $color-white;
      font-weight: normal;
      line-height: 1.1;
      // margin-block: 0.25em;
      padding-block: 20%;
      padding-inline: 10%;
      text-align: center;

      @media #{$mq-760-up} {
        padding-block: 10%;
        // padding-inline: 20%;
      }

      @media #{$mq-900-up} {
        padding-block: 0;
        padding-inline: 20%;
      }

      h2 {
        @extend %font-gotham-bold;
        @include font-size(30px);
        font-weight: normal;
        line-height: 1.4;
        margin-block: 0.25em 0;

        @media #{$mq-760-up} {
          @include font-size(20px);
        }

        @media #{$mq-900-up} {
          @include font-size(40px);
          // margin-block: .25em 2em;
        }
      }

      .rule {
        background-image: url("../img/rule-arrows.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 11px;
        margin-inline: auto;
        margin-block: 1.5rem;
        width: 90px;
      }

      .block-copy {
        @include font-size(25px);
        font-weight: normal;
        line-height: 1.5;

        @media #{$mq-760-up} {
          @include font-size(18px);
        }

        @media #{$mq-900-up} {
          @include font-size(25px);
        }
      }
    }

    .photo {
      grid-column: 1;
      grid-row: 2;

      .sm {
        display: block;
        visibility: visible;
      }

      .lg {
        display: none;
        visibility: hidden;
      }

      img {
        width: 100%;
      }
    }

    .block-text {
      grid-column: 1;
      grid-row: 1;
    }

    @media #{$mq-760-up} {
      .photo {
        grid-row: 1;

        .sm {
          display: none;
          visibility: hidden;
        }

        .lg {
          display: block;
          visibility: visible;
        }
      }

      .block-text {
        grid-column: 2;

        grid-row: 1;
      }
    }
  }

  .featured-post .read-more {
    @extend %btn;
    display: block;
    margin-top: 2rem;
    padding-inline: 3em;
    margin-inline: auto;
    max-width: 250px;
  }
}

.page-template-page-contact {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper.jpg");
    background-repeat: repeat;
    color: $color-black;

    .inner {
      padding-block: 10% 5%;
      padding-inline: 5%;

      @media #{$mq-760-up} {
        padding-block: 5% 0;
        padding-inline: 28%;
      }
    }

    h1 {
      @extend %small-caps;
      @include font-size(41px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0;
    }

    p {
      @include font-size(18px);
      font-weight: normal;
      line-height: 1.4;
      max-width: 50ch;

      @media #{$mq-760-up} {
        @include font-size(21px);
      }
    }

    form {
      padding-block: 1.5rem 5%;
      position: relative;

      &::before {
        background-color: transparent;
        border-top: 4px solid $color-mid-gray;
        border-bottom: 2px solid $color-mid-gray;
        content: "";
        display: block;
        height: 12px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      p {
        max-width: unset;
      }

      label {
        @extend %font-janson;
        @extend %small-caps;
        @include font-size(18px);
        color: $color-mid-gray;
        display: block;
        line-height: 1;
        text-transform: lowercase;

        @media #{$mq-760-up} {
          @include font-size(21px);
        }
      }

      input,
      textarea {
        @extend %font-janson;
        @include font-size(18px);
        background: rgba($color-white-actual, 0.7);
        box-shadow: inset 0 0 0 1px $color-mid-gray;
        border: none;
        border-radius: 0;
        color: $color-tan;
        outline: none;
        padding: 0.5em;
        padding: 0.65em 0.75em 0.5em;
        transition: all $trans-duration ease;
        width: 100%;

        @media #{$mq-760-up} {
          @include font-size(21px);
        }

        &:focus {
          background: $color-white-actual;
          color: $color-black;
        }

        .wpcf7-not-valid-tip {
          color: $color-rust;
        }
      }

      .wpcf7-submit {
        @extend %btn;
        // background-color: $color-black;
        padding-inline: 5em;
        width: auto;

        @media #{$mq-760-up} {
          // padding-inline: 5em;
        }

        &:focus,
        &:hover {
          // background-color: $color-black-actual;
        }
      }
    }

    .secondary {
      background-color: $color-tan;
      background-image: url("../img/bkg-paper-darker.jpg");
      background-repeat: repeat;
      padding-block: 0 5%;

      .inner {
        box-shadow: inset 0 3px 3px rgba($color-green-extra-dark, 0.2);
        padding-block: 15% 10%;
        padding-inline: 5%;
        text-align: center;

        @media #{$mq-760-up} {
          padding-block: 5% 0;
          padding-inline: 28%;
        }
      }

      .address {
        @extend %font-janson;
        @extend %small-caps;
        @include font-size(21px);
        display: block;
        line-height: 1.5;
        padding-block: 2em 1em;

        @media #{$mq-760-up} {
          padding-block: 3em 2em;
        }
      }

      .email-address,
      .phone-numbers {
        @include font-size(20px);
        line-height: 1;
        padding-block: 0.5em 0;

        .label {
          @extend %font-janson-bold;
        }
      }

      .phone-numbers {
        display: flex;
        column-gap: 0.5em;
        flex-direction: column;
        justify-content: center;

        @media #{$mq-760-up} {
          flex-direction: row;
          margin-top: 0.5em;
        }

        .phone-num {
          padding-block: 0.5em;

          @media #{$mq-760-up} {
            padding-block: 0;
          }

          &:first-of-type {
            @media #{$mq-760-up} {
              border-right: 2px solid $color-black;
              padding-right: 0.5em;
            }
          }
        }
      }

      a,
      a:visited {
        color: $color-black;
        text-decoration: none;
      }

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }
    }
  }
}

// Find a Retailer page

.page-template-page-find {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper.jpg");
    background-repeat: repeat;
    color: $color-black;

    .inner {
      padding-block: 10% 5%;
      padding-inline: 5%;

      @media #{$mq-760-up} {
        // padding-block: 5% 10%;
        padding-block: 5% 5%;
        // padding-inline: 28%;
        padding-inline: 10%;
      }

      a,
      a:visited {
        color: $color-rust;
        text-decoration: underline;
      }

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }
    }

    h1 {
      @extend %small-caps;
      @include font-size(35px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0;
      text-align: center;

      @media #{$mq-760-up} {
        @include font-size(50px);
      }
    }

    p {
      @include font-size(18px);
      font-weight: normal;
      line-height: 1.4;
      margin-inline: auto;
      max-width: 28ch;
      text-align: center;

      @media #{$mq-760-up} {
        @include font-size(21px);
        max-width: 34ch;
      }
    }

    h2 {
      @include font-size(41px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0 1rem;
      padding-block: 3rem 1rem;
      position: relative;

      &::after {
        background-color: transparent;
        border-top: 4px solid $color-mid-gray;
        border-bottom: 2px solid $color-mid-gray;
        content: "";
        display: block;
        height: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      p {
        max-width: unset;
      }

      .map-group {
        width: 100%;
      }

      a,
      a:visited {
        color: $color-black;
        text-decoration: none;
      }

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }
    }

    .wpsl-search {
      background: transparent;
    }

    #wpsl-search-btn {
      @extend %btn;
      background: $color-rust;
      // box-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.3);
      box-shadow: none;
      text-transform: uppercase !important;
    }

    #wpsl-result-list {
      background: $color-black;
      background: $color-green;
      color: $color-white;

      li {
        transition: all $trans-duration linear;
      }

      li:hover,
      li:focus {
        background: $color-green-light;
      }
    }

    #wpsl-search-input,
    .wpsl-dropdown .wpsl-selected-item {
      @extend %font-janson;
      @include font-size(18px);
      background: rgba($color-white-actual, 0.7);
      box-shadow: inset 0 0 0 1px $color-mid-gray;
      border: none;
      border-radius: 0;
      // color: $color-tan;
      outline: none;
      padding: 0.5em;
      padding: 0.65em 0.75em 0.5em;
      transition: all $trans-duration ease;
      // width: 100%;

      @media #{$mq-760-up} {
        @include font-size(21px);
      }

      &:focus {
        background: $color-white-actual;
        color: $color-black;
      }
    }

    #wpsl-search-wrap {
      label {
        text-align: center;
      }
    }

    .wpsl-store-location {
      strong {
        @extend %small-caps;
        @include font-size(18px);
        font-weight: normal;

        @media #{$mq-760-up} {
          @include font-size(21px);
        }
      }

      p {
        @include font-size(18px);
        font-weight: normal;
        line-height: 1.4;
        margin-inline: auto;
        margin-block: 1em 0 !important;
        max-width: unset;
        text-align: center;

        @media #{$mq-760-up} {
          // @include font-size(21px);
          // max-width: 34ch;
        }
      }

      a,
      a:visited {
        // color: $color-black;
        color: $color-white;
        // text-decoration: none;
        text-decoration: underline;
      }

      a:hover,
      a:active,
      a:focus {
        color: $color-white-actual;
        text-decoration: underline;
      }
    }

    .wpsl-direction-wrap {
      @extend %small-caps;
      // color: $color-green-light;
      // text-decoration: none;

      a.wpsl-directions,
      a.wpsl-directions:visited {
        // @extend %small-caps;
        // color: $color-green-light;
        // text-decoration: none;
        @extend %font-janson-italic;
        @include font-size(14px);
        color: $color-white;
        display: block;
        font-variant-caps: normal;
        font-variant-numeric: normal;
        text-decoration: underline;
        text-transform: none;
        line-height: 1.4;
        margin-block: 0;

        @media #{$mq-760-up} {
          @include font-size(16px);
        }
      }

      a.wpsl-directions:hover,
      a.wpsl-directions:active,
      a.wpsl-directions:focus {
        color: $color-white-actual;
        text-decoration: underline;
      }
    }

    .wpsl-info-window {
      p {
        @include font-size(18px);
        font-weight: normal;
        line-height: 1.4;
        padding-inline: 0.25em;
        margin-block: 0.5em 0 !important;
        max-width: unset;
        text-align: center;
      }

      strong {
        font-weight: normal;
      }

      a,
      a:visited {
        color: $color-green-dark;
        // color: $color-white;
        // text-decoration: none;
        // text-decoration: underline;
      }

      a:hover,
      a:active,
      a:focus {
        color: $color-black;
        // color: $color-white-actual;
        text-decoration: underline;
      }
    }
  }
}

.page-template-page-media-library {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper.jpg");
    background-repeat: repeat;
    color: $color-black;

    .inner {
      padding-block: 10% 5%;
      padding-inline: 5%;

      @media #{$mq-760-up} {
        // padding-block: 5% 10%;
        padding-block: 5% 2%;
        padding-inline: 28%;
        // padding-inline: 10%;
      }
    }

    h1 {
      @extend %small-caps;
      @include font-size(35px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0;
      text-align: center;

      @media #{$mq-760-up} {
        @include font-size(50px);
      }
    }

    p,
    ol,
    ul {
      @include font-size(18px);
      font-weight: normal;
      line-height: 1.4;
      margin-inline: auto;
      // text-align: center;

      @media #{$mq-760-up} {
        @include font-size(21px);
      }
    }

    h2 {
      @include font-size(41px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0 1rem;
      padding-block: 2rem 0;
      position: relative;

      // &::after {
      //   background-color: transparent;
      //   border-top: 4px solid $color-mid-gray;
      //   border-bottom: 2px solid $color-mid-gray;
      //   content: "";
      //   display: block;
      //   height: 12px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      // }

      p {
        max-width: unset;
      }
    }
    .desc {
      @include font-size(18px);
      margin-block: 1em 0;
    }

    a,
    a:visited {
      color: $color-green-dark;
      text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus {
      color: $color-green;
      text-decoration-color: $color-rust-light;
    }

    .secondary {
      background-color: $color-tan;
      background-image: url("../img/bkg-paper-darker.jpg");
      background-repeat: repeat;
      padding-block: 0 5%;

      .inner {
        box-shadow: inset 0 3px 3px rgba($color-green-extra-dark, 0.2);
        padding-block: 15% 10%;
        padding-inline: 5%;
        // text-align: center;

        @media #{$mq-760-up} {
          padding-block: 2% 0;
          // padding-block: 0 5%;
          padding-inline: 28%;
        }
      }
    }

    .group {
      padding-bottom: 1em;

      h2 {
        color: $color-green-light;
      }
    }

    .dl-file {
      @extend %font-gotham-bold;
      @include font-size(18px);
      align-items: center;
      color: $color-rust-light;
      display: inline-flex;
      gap: 0.5em;
      font-weight: normal;
      line-height: 1.4;
      margin-inline: auto;
      // text-align: center;

      @media #{$mq-760-up} {
        @include font-size(21px);
      }

      a:visited {
        color: $color-rust-light;
        text-decoration: underline;
      }

      a:hover,
      a:active,
      a:focus {
        color: $color-rust-light;
        text-decoration-color: $color-rust-light;
      }

      .icon {
        height: auto;
        width: 1em;
      }
    }
  }
}

.single-post {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper.jpg");
    background-repeat: repeat;
    color: $color-black;
    padding-block: 10% 5%;
    padding-inline: 5%;

    @media #{$mq-760-up} {
      padding-block: 5% 10%;
      padding-inline: 28%;
    }

    .byline {
      @extend %font-neue-helvetica;
      @include font-size(14px);
      margin: 0;
      padding-block: 2.5em 0;
      text-transform: uppercase;
    }

    .article-h1 {
      @extend %font-gotham-bold;
      @include font-size(30px);
      font-weight: normal;
      line-height: 1.4;
      margin-block: 0.25em 1em;

      @media #{$mq-760-up} {
        @include font-size(40px);
      }
    }

    p,
    ol,
    ul {
      @include font-size(18px);
      font-weight: normal;
      line-height: 1.4;
      margin-inline: auto;
      // text-align: center;

      @media #{$mq-760-up} {
        @include font-size(21px);
      }
    }

    h2 {
      @include font-size(41px);
      font-weight: normal;
      line-height: 1.1;
      margin-block: 0 1rem;
      padding-block: 2rem 0.5rem;
      position: relative;

      // &::after {
      //   background-color: transparent;
      //   border-top: 4px solid $color-mid-gray;
      //   border-bottom: 2px solid $color-mid-gray;
      //   content: "";
      //   display: block;
      //   height: 12px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      // }

      p {
        max-width: unset;
      }
    }

    a,
    a:visited {
      color: $color-green-dark;
      text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus {
      color: $color-green;
      text-decoration-color: $color-rust-light;
    }
  }
}

// Drinks Page
.page-template-page-drinks {
  .main-content {
    background-color: $color-white;
    background-image: url("../img/bkg-paper-darker.jpg");
    background-repeat: repeat;
    color: $color-black;
    padding-block: 5%;
    padding-inline: 5%;

    @media #{$mq-900-up} {
      padding-inline: 15%;
    }
  }

  .recipe {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    padding-block: 0 1rem;

    @media #{$mq-760-up} {
      grid-template-columns: 1fr 1fr;
      padding-block: 3rem;
    }

    .block-text {
      padding-block: 5% 0;

      @media #{$mq-760-up} {
        padding-inline: 20% 10%;
      }

      h2 {
        @extend %small-caps;
        @include font-size(35px);
        font-weight: normal;
        line-height: 1.1;
        margin-block: 0.25em;

        @media #{$mq-760-up} {
          @include font-size(50px);
        }
      }

      ul {
        list-style: none;
        margin: 0 0 -0.5em;
        padding: 0;

        li {
          padding-block: 0.5em;
        }
      }

      .ingredients,
      .method {
        @include font-size(22px);
        font-weight: normal;
        line-height: 1.4;

        @media #{$mq-900-up} {
          @include font-size(25px);
        }
      }
    }
  }
}

.page-template-page-landing {
  .page-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding: 0 5% 0 7.5%;
    width: auto;

    @media #{$mq-760-up} {
      align-items: center;
      background-size: cover;
      // column-gap: 4%;
      column-gap: 0;
      display: grid;
      grid-template-columns: 3.4fr 6.6fr;
      flex-direction: row;
      padding: 0;
    }

    .hero-img {
      align-self: center;
      margin-top: 5%;
      margin-right: 15%;
      width: 66%;

      @media #{$mq-760-up} {
        margin-left: 0;
        margin-right: unset;
        margin-top: 0;
        justify-self: end;
        width: auto;
      }
    }

    .page-title {
      @extend %condensed-headline;
      @include font-size(150px);
      text-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.5);
      line-height: 1;
      margin-block: 0.75em 0;
      max-width: 18ch;
      text-transform: uppercase;

      @media #{$mq-760-up} {
        @include font-size(90px);
        margin-block: 0;
      }
    }

    h2 {
      @include font-size(24px);
      text-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.5);
      font-weight: normal;
      line-height: 1;
      margin-block: 0.5em;

      @media #{$mq-760-up} {
        @include font-size(32px);
      }
    }

    .btns {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-block: 2rem 1rem;

      @media #{$mq-760-up} {
        flex-direction: row;
      }

      .btn {
        @extend %btn;
        box-shadow: 6px 3px 3px rgba($color-green-extra-dark, 0.3);
        text-align: center;
      }

      .alt {
        @extend %btn;
        background-color: $color-rust-light;
        padding-inline: 3.5em;

        &:focus,
        &:hover {
          background-color: $color-rust;
        }
      }
    }
  }
}
// Page globals

.article-h1 {
  // margin-top: 12vw;

  // color: $color-white;

  .article-content {
    background-color: $color-white;
    padding: 2vw 0;

    .article-intro {
      // @extend %font-calluna;
      @include font-size(21px);
      font-weight: normal;
      line-height: 1.5;
      margin-block: 2rem 2rem;
      margin-inline: 2rem;
      max-width: 45ch;

      @media #{$mq-900-up} {
        font-size: 2.5vw;
        margin-inline: 8vw;
      }

      @media #{$mq-1400-up} {
        @include font-size(36px);
        margin-inline: 8rem;
      }
    }

    .article-body {
      @include font-size(18px);
      background-color: $color-white;
      border-radius: 1em;
      margin-inline: 1rem;
      padding: 1rem;
      // max-width: 1140px;

      @media #{$mq-900-up} {
        font-size: 1.65vw;
        margin-inline: 4vw;
        padding: 2vw 4vw;
      }

      @media #{$mq-1400-up} {
        @include font-size(22px);
        margin-inline: 4rem;
        padding: 2rem 4rem;
      }
    }
  }

  .single-post {
    .article-content {
      .article-body {
        background-color: transparent;
      }
    }
  }

  .comment-box {
    // background-color: $color-light-grey;
    padding: 0 0 2vw;

    .comments {
      @include font-size(18px);
      // background-color: $color-white;
      // border-radius: 1em;
      margin-inline: 1rem;
      padding: 1rem;
      // max-width: 1140px;

      @media #{$mq-900-up} {
        font-size: 1.65vw;
        margin-inline: 4vw;
        padding: 2vw 4vw;
      }

      @media #{$mq-1400-up} {
        @include font-size(22px);
        margin-inline: 4rem;
        padding: 2rem 4rem;
      }
    }
  }

  .page-template-page-areas {
    .article-body {
      h3 {
        font-size: 1.25em;
        font-weight: normal;
        margin-block: 1.75em 0;
      }
    }
  }
}
